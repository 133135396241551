<div style="background: #fefcf4">
  <div class="hero-section">
    <div class="hero-image">
      <picture>
        <source srcset="
            ../../../assets/images/alltours/converted/city-480w.webp 480w,
            ../../../assets/images/alltours/converted/city-800w.webp 800w,
            ../../../assets/images/alltours/converted/city.webp 1200w
          " type="image/webp" sizes="100vw" />
        <source srcset="
            ../../../assets/images/alltours/converted/city-480w.jpg 480w,
            ../../../assets/images/alltours/converted/city-800w.jpg 800w,
            ../../../assets/images/alltours/converted/city.jpg 1200w
          " type="image/jpeg" sizes="100vw" />
        <img src="../../../assets/images/alltours/converted/city.webp" alt="City background" loading="eager"
          fetchpriority="high" />
      </picture>
    </div>
    <div class="hero-overlay">
      <div class="hero-content">
        <div class="hero-text">
          <h1 id="landingTitle">{{ strings.any_city_any_language }}</h1>
          <p id="landingSubtitle">{{ strings.home_sub_title_text }}</p>
          <app-search></app-search>
        </div>
      </div>
    </div>
  </div>

  <div class="featured-tours-section">
    <div class="section-header">
      <h2 class="screenshotTitle">Featured Tours</h2>
      <p class="section-subtitle">Discover our most popular audio tours</p>
      <div class="decorative-line"></div>
    </div>
    <div class="container">
      <div class="row" *ngIf="featuredTours.length == 0">
        <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let number of [0,1,2]">
          <app-tour-preview [tour]="undefined"></app-tour-preview>
        </div>
      </div>
      <div class="row" style="place-content: center" [@fadeInUp]="featuredTours.length == 0 ? 'out' : 'in'">
        <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let tour of featuredTours">
          <app-tour-preview [tour]="tour"></app-tour-preview>
        </div>
      </div>
    </div>
  </div>

  <div class="cities-section">
    <div class="section-header">
      <h2 class="screenshotTitle">Explore Cities Worldwide</h2>
      <p class="section-subtitle">Discover audio tours in cities around the globe</p>
      <div class="decorative-line"></div>
    </div>
    <div class="container">
      <div class="row" style="place-content: center">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="city-card" routerLink="/cities">
            <div class="city-image">
              <img src="../../../assets/images/alltours/converted/city2.webp" alt="City background" loading="lazy" />
              <div class="city-overlay">
                <span class="material-symbols-rounded">explore</span>
                <h3>View All Cities</h3>
                <p class="city-card-subtitle">1000+ cities available</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="features-section">
    <div class="container">
      <div *ngFor="let screenshot of screenshots" class="row feature-row">
        <div *ngIf="screenshot.id % 2 == 0" class="col-lg-6 col-sm-12 feature-text">
          <div class="feature-content left">
            <h2 class="feature-title">{{ screenshot.title }}</h2>
            <p class="feature-description">{{ screenshot.text }}</p>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 feature-image">
          <picture>
            <source srcset="
                {{ screenshot.img.replace('.png', '-480w.webp') }} 480w,
                {{ screenshot.img.replace('.png', '-800w.webp') }} 800w,
                {{ screenshot.img.replace('.png', '.webp') }} 1200w
              " type="image/webp" sizes="(max-width: 576px) 100vw, (max-width: 992px) 50vw, 33vw" />
            <source srcset="
                {{ screenshot.img.replace('.png', '-480w.png') }} 480w,
                {{ screenshot.img.replace('.png', '-800w.png') }} 800w,
                {{ screenshot.img.replace('.png', '.png') }} 1200w
              " type="image/png" sizes="(max-width: 576px) 100vw, (max-width: 992px) 50vw, 33vw" />
            <img src="{{ screenshot.img }}" alt="{{ screenshot.title }} screenshot" loading="lazy" />
          </picture>
        </div>
        <div *ngIf="screenshot.id % 2 == 1" class="col-lg-6 col-sm-12 order-first order-lg-last feature-text">
          <div class="feature-content right">
            <h2 class="feature-title">{{ screenshot.title }}</h2>
            <p class="feature-description">{{ screenshot.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>