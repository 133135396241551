import { Component, Inject, OnInit, PLATFORM_ID, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import topCitiesJson from '../../../../assets/json/top_tourist_cities.json';
import miniCitiesJson from '../../../../assets/json/mini_cities.json';
import { ActivatedRoute, Router } from '@angular/router';
import stringsJson from '../../../../assets/strings/strings.json';
import { stringFormat } from '../../../utils/utils';
import countriesNamesJson from '../../../../assets/json/countries.json';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

interface City {
  id: number;
  name: string;
  names: { [key: string]: string };
  countryCode: string;
  country: string;
}

interface CountryGroup {
  countryCode: string;
  cities: City[];
}

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.css', '../../../app.component.css']
})
export class CitiesComponent implements OnInit {
  cities: City[] = [];
  countries: CountryGroup[] = [];
  title: string;
  selectedCountry: string;
  strings: any;
  language: string;
  countryNames: any;
  breadcrumbName: string;
  otherCountries = [];
  overview

  constructor(
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) public platformId: Object,
    @Inject(DOCUMENT) public _document: Document,
  ) {
  }

  ngOnInit() {
    this.getOverview()
    this.initializeLanguage();
    this.loadCitiesAndCountries();
    this.setSeoText();
  }

  async getOverview() {
    this.overview = (await getDoc(doc(getFirestore(), 'overview', 'latest'))).data()
    console.log("overview", this.overview);

    let otherCountries = Object.keys(this.countryNames).map(c => {
      return {
        id: c,
        name: this.countryNames[c]["en"],
        total: 0
      }
    }).filter(c => !this.overview?.countries.map(c => c.id).includes(c.id));
    this.otherCountries = otherCountries.sort((a, b) => a.name.localeCompare(b.name));
  }

  private initializeLanguage() {
    if (isPlatformBrowser(this.platformId)) {
      this.language = window.location.hostname.split('.')[0];
    }
    if (this.language == undefined || this.language.length > 2) {
      this.language = 'en';
    }
    this.strings = JSON.parse(JSON.stringify(stringsJson))['default'][this.language];
    this.countryNames = JSON.parse(JSON.stringify(countriesNamesJson));
  }

  private loadCitiesAndCountries() {
    const countryName = this.route.snapshot.paramMap.get('countryName')?.toLowerCase();
    const countryCode = countryName ? this.findCountryCode(countryName) : undefined;

    this.selectedCountry = countryCode?.toLowerCase();

    if (!countryCode) {
      this.loadTopCities();
    } else {
      this.loadCountryCities(countryCode);
    }
  }

  private findCountryCode(countryName: string): string | undefined {
    return Object.keys(this.countryNames).find(
      key => this.countryNames[key][this.language].toLowerCase() === countryName
    );
  }

  private loadTopCities() {
    this.title = 'Top 100';
    this.breadcrumbName = this.strings.cities;
    const allCities = miniCitiesJson.cities as City[];

    this.cities = topCitiesJson.cities
      .map(c => allCities.find(a => a.id === c.id))
      .filter((c): c is City => c !== undefined);

    this._document.title = this.strings.alltours_audio_tours;

    // Group cities by country
    const groupedCities = allCities.reduce((groups, city) => {
      if (!groups[city.countryCode]) {
        groups[city.countryCode] = { countryCode: city.countryCode, cities: [] };
      }
      groups[city.countryCode].cities.push(city);
      return groups;
    }, {} as { [key: string]: CountryGroup });

    console.log("countries", this.countries);
  }

  private loadCountryCities(countryCode: string) {
    this.cities = (miniCitiesJson.cities as City[]).filter(
      c => c.countryCode.toLowerCase() === countryCode.toLowerCase()
    );

    if (this.cities.length > 0) {
      const countryName = this.countryNames[this.cities[0].countryCode][this.language];
      this.breadcrumbName = countryName;
      this._document.title = stringFormat(this.strings.alltours_tours_in_country, countryName);
      this.title = countryName;
    }
  }

  // TrackBy functions for better performance
  trackByCity(index: number, city: City): number {
    return city.id;
  }

  trackByCountry(index: number, group: CountryGroup): string {
    return group.countryCode;
  }

  setSeoText() {
    this._document.title = 'AllTours - ' + this.strings.create_tours_in_cities;
    const descElement = this._document.head.children.namedItem('description');
    if (descElement) {
      descElement['content'] = this.strings.seo_description_default;
    }
  }

  get totalTours(): number {
    if (!this.overview?.cities?.length) return 0;
    return this.overview.cities.reduce((sum: number, city: any) => sum + (city.total || 0), 0);
  }
}
