<div class="hero-section">
  <div class="hero-image">
    <img *ngIf="!loadingCity && isCityPage" class="heroImg"
      [src]="city.image ? city.image.large : '../../../assets/images/alltours/screenshots/audio.jpg'"
      [alt]="'Cityscape of ' + city.name"
      onerror="this.onerror=null; this.src='../../../assets/images/alltours/screenshots/audio.jpg'" />
    <img *ngIf="!loadingCity && !isCityPage" class="heroImg" alt="Guy wearing headphones"
      src="../../../assets/images/alltours/screenshots/audio.jpg" />
    <div *ngIf="loadingCity" class="heroImg shimmer"></div>
  </div>
  <div class="imgCover"></div>
  <div class="container ontopCover">
    <nav class="breadcrumb-nav" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="bread-link text-capitalize" href="">{{ strings.home }}</a>
        </li>
        <li class="breadcrumb-item">
          <a class="bread-link text-capitalize" href="/cities">Cities</a>
        </li>
        <li class="breadcrumb-item">
          <a class="bread-link text-capitalize" href="/tour/{{ countryName.toLowerCase() }}">
            {{ countryName }}
          </a>
        </li>
        <li class="breadcrumb-item text-capitalize" aria-current="page" *ngIf="city != undefined">
          {{ city.names[language] ?? city.name }}
        </li>
      </ol>
    </nav>
    <div class="cityLanding">
      <h1 class="title">{{ title }}</h1>
      <p class="subtitle">{{ subTitle }}</p>
      <div *ngIf="!loadingTours && tours.length == 0" class="cta-container">
        <button type="button" (click)="goToApp()" class="ctaBtn">
          Get App
        </button>
      </div>
    </div>
  </div>
</div>

<div class="content-section">
  <div class="container">
    <div class="row" *ngIf="loadingTours && isCityPage">
      <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let number of [0,1,2]">
        <app-tour-preview [tour]="undefined"></app-tour-preview>
      </div>
    </div>
    <div class="row" *ngIf="loadingTours && !isCityPage">
      <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let number of [0,1,2,3,4,5]">
        <app-tour-preview [tour]="undefined"></app-tour-preview>
      </div>
    </div>
    <div class="row tours-grid" [@fadeInUp]="loadingTours ? 'out' : 'in'">
      <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let tour of tours.slice(0,3)">
        <app-tour-preview [tour]="tour"></app-tour-preview>
      </div>
    </div>

    <div class="map-section card" *ngIf="isCityPage">
      <div class="row">
        <div class="col-12 col-lg-8">
          <app-landmark-map [landmarks]="cityLandmarks" [city]="city" (onLandmarkClicked)="onLoginToMakeTour()">
          </app-landmark-map>
        </div>
        <div class="col-12 col-lg-4">
          <div class="mapInfoHolder">
            <h5 class="map-title">Create your own tour in {{ localisedCityName }}</h5>
            <div class="step-container" *ngFor="let step of howItWorksCreateTour">
              <span class="material-symbols-rounded icon">{{ step.icon }}</span>
              <div class="step-content">
                <b class="primaryText">{{ step.title }}</b>
                <p class="secondaryText">{{ step.description }}</p>
              </div>
            </div>
            <div class="cta-container">
              <button type="button" (click)="onLoginToMakeTour()" class="ctaBtnMini">
                Create a tour
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row tours-grid-extended" *ngIf="tours != undefined && tours.length > 3"
      [@fadeInUp]="loadingTours ? 'out' : 'in'">
      <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let tour of tours.slice(3,12)">
        <app-tour-preview [tour]="tour"></app-tour-preview>
      </div>
    </div>

    <div class="cities-section" *ngIf="!isCityPage && countryCities != undefined">
      <div class="col-12">
        <h3 class="section-title">Top Cities</h3>
      </div>
      <div class="row">
        <div *ngFor="let city of topCountryCities" class="col-6 col-md-4 col-lg-3 col-xl-2">
          <app-city-preview [city]="city"></app-city-preview>
        </div>
      </div>
      <div class="other-cities" *ngIf="countryCities.length > 6">
        <h3 class="section-title">Other Cities</h3>
        <div class="row">
          <div *ngFor="let city of countryCities" class="col-lg-2 col-md-3 col-sm-4 col-6">
            <a class="city-name" href="/tour/{{ city.country.toLowerCase() }}/{{ city.name.toLowerCase() }}">
              {{ city.names[language] ?? city.name }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isCityPage">
      <div class="col-12">
        <div id="article" [innerHtml]="article"></div>
      </div>
    </div>
  </div>
</div>

<div [innerHTML]="jsonLD"></div>

<button id="loginButton" type="button" class="hidden-button" data-bs-toggle="modal"
  data-bs-target="#loginModalCity"></button>
<button id="downloadAppButton" type="button" class="hidden-button" data-bs-toggle="modal"
  data-bs-target="#downloadAppModal"></button>

<div id="loginModalCity" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Please login</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <app-user-login (successfulLogin)="onSuccessfulLogin($event)"
          [loginMessage]="'To create a tour, please login first...'">
        </app-user-login>
      </div>
    </div>
  </div>
</div>

<div id="downloadAppModal" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">To create your own tour:</h5>
        <button id="closeLoginButton" type="button" class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <app-download-app [lastStepToShow]="'createTour'"></app-download-app>
      </div>
    </div>
  </div>
</div>