import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { LocalStorageService, StorageKey } from 'src/app/local-storage.service';
import { PurchasesService } from './purchases.service';
import { getFunctions, httpsCallableData } from '@angular/fire/functions';
import { Auth, onAuthStateChanged, signOut, User } from '@angular/fire/auth';
import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, query, serverTimestamp, setDoc, updateDoc, where } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private storage: LocalStorageService, private purchaseService: PurchasesService, private auth: Auth) {
    onAuthStateChanged(this.auth, (user) => {
      this.onUserAuth(user);
    })
    let userId = this.getUserId();
    if (userId) {
      this.listenToDoc(userId);
    }
  }

  isLoggedIn = new BehaviorSubject<boolean>(this.storage.getItem(StorageKey.USER_ID) != undefined)
  isLoggedIn$ = this.isLoggedIn.asObservable();

  userData;

  user = new BehaviorSubject<any>(undefined);
  user$ = this.user.asObservable();

  tours = new BehaviorSubject<any>([]);
  tours$ = this.tours.asObservable();

  userId;

  listenToDoc(userId) {
    onSnapshot(doc(getFirestore(), 'users', userId), (doc) => {
      if (doc.exists()) {
        this.onUserData(doc.data());
      }
    })
  }

  async onUserAuth(user) {
    console.log("Setting user as logged in", user != null)
    this.isLoggedIn.next(user != null);
    if (user) {
      await this.purchaseService.initRevenueCat(user.uid);
      this.storage.setItem(StorageKey.USER_ID, user.uid);
      this.storage.setItem(StorageKey.USER_EMAIL, user.email);
      this.listenToDoc(user.uid);
    } else {
      this.storage.removeItem(StorageKey.USER_ID);
    }
  }

  onUserData(user) {
    this.userData = user
    this.user.next(user)
    this.getUsersTours(user)
  }

  private getUsersTours(user) {
    let tourIds = user.purchasedTourIds
    if (tourIds == undefined || tourIds.length == 0) return
    this.tours.next([])
    let tours = []
    getDocs(query(collection(getFirestore(), 'guided-tours'), where('id', 'in', tourIds))).then(querySnapshot => {
      querySnapshot.forEach(doc => {
        let tour = doc.data()
        tour.purchased = true
        tours.push(tour)
      })
    })
    this.tours.next(tours)
  }

  getUserId() {
    let id = this.userId == undefined ? this.storage.getItem(StorageKey.USER_ID) : this.userId;
    return id;
  }

  getUserEmail() {
    let email = this.storage.getItem(StorageKey.USER_EMAIL) || this.auth.currentUser.email
    return email
  }

  async onLogin(firebaseUser: User) {
    this.userId = firebaseUser.uid;
    this.storage.setItem(StorageKey.USER_ID, this.userId);
    let docRef = doc(getFirestore(), 'users', firebaseUser.uid);
    let docD = await getDoc(docRef);
    if (docD.exists()) {
      this.onUserData(docD.data());
    } else {
      let data = {
        id: this.userId,
        created: serverTimestamp(),
        email: firebaseUser.email,
        name: firebaseUser.displayName,
        photo: firebaseUser.photoURL,
      };
      await setDoc(docRef, data);
      this.onUserData(data);
      await this.assignPartnerId(this.userId);
    }
    await this.onUserAuth(firebaseUser);
  }

  logout() {
    signOut(this.auth).then(() => {
      this.userData = undefined;
      this.user.next(undefined);
    });
  }

  setPartnerId(partnerId) {
    this.storage.setItem(StorageKey.PARTNER_ID, partnerId)
    let userId = this.getUserId()
    if (userId) {
      this.assignPartnerId(userId)
    }
  }

  async assignPartnerId(userId) {
    let partnerId = this.storage.getItem(StorageKey.PARTNER_ID);
    if (partnerId) {
      const linkUserWithPartner = httpsCallableData(getFunctions(), 'linkUserWithPartner');
      linkUserWithPartner({
        partnerId: partnerId,
        userId: userId
      }).subscribe((result: any) => {
      });
    }
  }

  hasPurchasedTour(tourId) {
    return this.userData != undefined && this.userData.purchasedTourIds != undefined && this.userData.purchasedTourIds.includes(tourId)
  }

  setCreateTourCityId(cityId) {
    updateDoc(doc(getFirestore(), 'users', this.getUserId()), {
      recentWebCreateTour: {
        cityId: cityId,
        date: serverTimestamp()
      }
    })
  }

}

@Injectable()
export class UserAuthGuard implements CanActivate {
  constructor(
    private _authService: UserService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authService.isLoggedIn.value) {
      return true;
    } else {
      this.router.navigate(['']); // Redirect to login if not authenticated
      return false;
    }
  }
}