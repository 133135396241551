#pageBackground {
  font-family:
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  background: radial-gradient(#3b4d00, #3b4d00, #3b4d00);
  color: white;
}

.tonal {
  color: #ebffa8 !important;
  background: #3b4d00 !important;
  font-weight: 500;
}

.ctaBtnMini {
  margin: 0;
  padding-left: 16px;
  height: 40px;
  padding-right: 16px;
  width: auto;
  background: #3b4d00;
  border: 0;
  font-family: Inter, sans-serif;
  font-weight: 700;
  color: #ffffff;
  min-width: fit-content;
  border-radius: 8px;
  font-size: 0.875rem;
  line-height: 1.5;
}

.contactBtn {
  margin: 0;
  padding-left: 16px;
  height: 40px;
  padding-right: 16px;
  width: auto;
  background: #efffb6;
  border: 1.5px #3b4d01 solid;
  font-family: Inter, sans-serif;
  font-weight: 500;
  color: #000000;
  min-width: fit-content;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
}

.smallIcon {
  font-size: inherit;
  margin-right: 4px;
  vertical-align: middle;
  align-self: center;
}

.imgCover {
  background: #000000aa;
  width: 100%;
  height: 95vh;
  position: absolute;
  color: white;
}

#landingIcon {
  height: 24px;
  width: 24px;
  vertical-align: middle;
  align-self: center;
}

#landingText {
  margin-left: 4px;
  color: #3b4d00;
  font-weight: 600;
}

.ctaBtn {
  margin: 0;
  height: 46px;
  width: auto;
  background: #daff5e;
  border: 0;
  font-family: Inter, sans-serif;
  font-weight: 600;
  padding-right: 24px;
  padding-left: 24px;
  font-size: 14px;
  color: #000000;
  border-radius: 8px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 15px;
  line-height: 1.5;
  border: 1.5px solid #000;
}

#brandHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.appDescription {
  border-radius: 30px;
  background-color: white;
  padding: 26px;
  font-family:
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  color: #595959;
}

.contentInnerBox {
  margin-bottom: 0px;
  border-radius: 16px;
  height: 100%;
}

.innerBottomBox {
  margin-top: 0px;
}

.innerBoxTitle {
  font-size: 4rem;
  text-align: center;
  margin-bottom: 2rem;
}

.iconImg {
  padding-right: 20px;
}

.bigIconImg {
  display: block;
  margin: auto;
}

.detailTxtBox {}

.detailTxtImgBox {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
}

.featureMiniTitle {
  font-size: 1.25rem;
}

.featureDescription {
  font-weight: 400;
  color: #777777;
  line-height: 1.2;
  margin-top: 2px;
}

.marketingPointsBox {
  text-align: center;
  margin-bottom: 32px;
}

.marketingPointTitle {
  font-size: 2rem;
  margin-top: 4px;
  margin-bottom: 8px;
}

#topMsgBox {
  margin: auto;
}

@media (min-width: 992px) {
  .marketingPointsBox {
    margin-bottom: 0px;
  }
}

@media (max-width: 992px) {
  .innerBottomBox {
    margin-top: 50px;
  }
}

@media (min-width: 576px) {
  .contentInnerBox {
    padding: 2rem 2rem;
  }
}

@media (max-width: 500px) {
  .appDescription {
    padding: 32px;
  }

  .innerBoxTitle {
    font-size: 3rem;
  }
}

#topContent {
  min-height: 60vh;
  padding: 25px;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-left: 24px;
  color: white;
  font: 'Baumans';
}

#topTitle {
  font-size: 5rem;
}

#subTitle {
  font-size: 1.5rem;
}

#topImg {
  width: 267px;
  height: 400px;
}

.featureDemo {
  width: 100%;
  background-color: white;
  border-radius: 30px;
  padding-left: 96px;
  padding-right: 96px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.featureImg {
  width: 300px;
  height: 562.5px;
}

.featureIcon {
  height: 40px;
  width: 40px;
  margin-right: 16px;
}

#homeBtn {
  position: absolute;
  margin-top: 24px;
  margin-left: 24px;
}

.featureRow {}

.divider {
  display: block;
  width: 100%;
  border-top: 1px solid #cfd8dc;
  height: 1px;
  background: #e8eaed;
  margin-top: 0;
}

.featureText {
  display: block;
  margin-top: 50px;
}

.featureTitle {
  margin-bottom: 16px;
  font-weight: bold;
}

.featureDescription {
  font-size: 18px;
}

.featureImgBox {
  text-align: center;
}

#topImgBox {
  text-align: center;
}

#demoContent {
  color: #595959;
}

#createAccBtn {
  padding: 0px;
  border-radius: 200px;
  border-width: 10px;
  border-color: #e9ecef;
}

#pointsHolder {
  padding: 48px;
}

@media (max-width: 770px) {
  #topTitle {
    font-size: 4rem;
    text-align: center;
  }

  #subTitle {
    text-align: center;
  }

  .featureImg {
    width: 200px;
    height: 375px;
  }

  #topImgBox {
    display: none;
  }

  #pointsHolder {
    padding: 0px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media (max-width: 550px) {
  .featureDemo {
    padding-left: 48px;
    padding-right: 48px;
  }

  #topTitle {
    font-size: 3rem;
  }
}

@media (max-width: 990px) {
  .featureText {
    text-align: center;
  }
}

@media (max-width: 400px) {
  #createAccBtn {
    width: 100%;
  }
}

.nextprev {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
}

.nextprev:hover {
  background-color: #ddd;
  color: black;
}

.round {
  border-radius: 50%;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  border-radius: 8px;
  padding: 12px;
  border: 0
}

/* On mouse-over, add a deeper shadow */

.review-date {
  font-size: 12px;
  margin-left: 8px;
}

.top-bottom-margin {
  margin-top: 8px;
  margin-bottom: 8px;
}

.icon-holder {
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
}

.app-icon {
  width: 148px;
  cursor: pointer;
}

a.bread-link {
  color: #daff5d;
}

.languageDropDown {
  background-repeat: no-repeat;
  background-color: #eee;
  margin-left: 16px;
  height: 40px;
  width: 64px;
  background-position: center;
}

.navBtn {}

.navBtnText {
  display: none;
}

@media (max-width: 992px) {
  .navBtn {
    display: none;
  }

  .navBtnText {
    display: block;
  }

  .languageDropDown {
    height: 32px;
    margin-left: 0px;
  }
}

.shimmer {
  position: relative;
  background-color: #ddd;
  color: transparent;
  border-radius: 4px;
  overflow: hidden;
  width: fit-content;
}

.shimmer::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.ontopCover {
  z-index: 1;
  color: white;
}

.landingContent {
  min-height: 92vh;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
}

.centralMedia {
  text-align: center;
  align-items: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

#footer {
  padding-bottom: 72px;
}

@media print {
  nav {
    display: none;
  }

  #footer {
    display: none;
  }
}

.topPartnerHeader {
  font-size: 1.75rem;
  font-weight: 500;
  padding-top: 32px;
  padding-bottom: 32px;
  text-align: start;
}

.loadingPage {
  text-align: center;
  height: 100vh;
  background: #fafafa;
  justify-content: center;
  color: #0008;
  display: flex;
}

.createAccountBtn {
  background: #3b4d00;
  border-radius: 4px;
  color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 500;
  height: 40px;
  align-content: center;
  font-size: 14px;
  width: max-content;
}

.loginBtn {
  border-radius: 4px;
  color: #3b4d00;
  border: 1.5px solid #3b4d00;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 500;
  height: 40px;
  align-content: center;
  font-size: 14px;
  width: max-content;
}

.bottomButtonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  background: white;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px;
  z-index: 2;
  transition: transform 0.25s ease;
}

li {
  --bs-breadcrumb-divider-color: #fff;
}

.breadcrumb {
  padding-top: 16px;
}

.secondaryText {
  color: #0008;
  font-weight: 400;
  margin-bottom: 0px;
}

.primaryText {
  font-weight: bold;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: inherit;
  box-shadow: none;
}

.tourPreviewOuterContainer {
  padding: 0;
  text-align: -webkit-center;
}

.textIcon {
  vertical-align: middle;
  font-size: 16px;
}

.borderTop {
  border-top: 1px solid #0002;
  padding-top: 16px;
  margin-top: 32px;
}

.material-symbols-rounded {
  vertical-align: middle;
  line-height: unset;
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

.topCardHolder {
  padding: 32px;
  background: #f0f0f0;
  border-radius: 16px;
  margin-bottom: 32px;
}

a {
  cursor: pointer;
}

.noUnderline {
  text-decoration: none;
}