<div class="container" style="padding-top: 32px; padding-bottom: 64px;">
  <div class="row justify-content-center">
    <div class="col-12 col-md-8">
      <h1>Returns & Refunds Policy</h1>

      <div class="policy-section">
        <h2>Our Commitment</h2>
        <p>At AllTours, we are committed to ensuring your satisfaction with our audio tours. If you're not completely
          satisfied with your purchase, we're here to help.</p>
      </div>

      <div class="policy-section">
        <h2>Refund Process</h2>
        <div class="step-container">
          <div class="step">
            <span class="step-number">1</span>
            <div class="step-content">
              <h3>Contact Us</h3>
              <p>Email us at <a href="mailto:kes@alltours.app">kes&#64;alltours.app</a> with your order details and
                reason
                for refund.</p>
            </div>
          </div>

          <div class="step">
            <span class="step-number">2</span>
            <div class="step-content">
              <h3>Include Required Information</h3>
              <p>Please provide:</p>
              <ul>
                <li>Your order number or booking reference</li>
                <li>Date of purchase</li>
                <li>Tour name</li>
                <li>Reason for refund request</li>
              </ul>
            </div>
          </div>

          <div class="step">
            <span class="step-number">3</span>
            <div class="step-content">
              <h3>Review Process</h3>
              <p>We will review your request within 1-2 business days and respond with next steps.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="policy-section">
        <h2>Refund Eligibility</h2>
        <ul>
          <li>Refund requests must be made within 14 days of purchase</li>
          <li>Tours that haven't been started or downloaded are eligible for a full refund</li>
          <li>Technical issues preventing tour access will be evaluated on a case-by-case basis</li>
        </ul>
      </div>

      <div class="policy-section">
        <h2>Processing Time</h2>
        <p>Once approved, refunds will be processed within 5-7 business days. The time it takes for the refund to appear
          in your account may vary depending on your payment method and financial institution.</p>
      </div>

      <div class="policy-section">
        <h2>Questions?</h2>
        <p>If you have any questions about our returns and refunds policy, please contact us at <a
            href="mailto:kes@alltours.app">kes&#64;alltours.app</a></p>
      </div>
    </div>
  </div>
</div>