import { Component, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID, ViewChild, ExperimentalPendingTasks as PendingTasks, inject, makeStateKey } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { langs } from '../../../utils/langs';
import stringsJson from '../../../../assets/strings/strings.json';
import citiesJson from '../../../../assets/json/mini_cities.json';
import { LngLatBounds } from 'mapbox-gl';
import { setSeoText, stringFormat } from '../../../utils/utils';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
// import exampleTour from '../../../../assets/json/example_tour.json';
import { PurchasesService } from '../purchases.service';
import { UserService } from '../user.service';
import { slideInOutAnimation } from 'src/app/utils/animations';
import { PartnerService } from '../../partner/partner-service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, where } from '@angular/fire/firestore';
import { TourService } from '../tour.service';
// import httpclient
import { HttpClient } from '@angular/common/http';
import { SeoService } from 'src/app/seo.service';
import { BreadcrumbService } from 'src/app/bread-crumb.service';
import { logEventMethod } from '../../../utils/logging';
const DATA_KEY = makeStateKey<any>('tour_details');

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css', '../../../app.component.css'],
  animations: [slideInOutAnimation]
})
export class TourComponent implements OnInit {
  private pendingTasks = inject(PendingTasks);
  tour;
  landmarks;
  subTitle;
  description;
  playingState = NOT_PLAYING;
  audio;
  strings;
  language;
  tourId;
  cities = citiesJson.cities;
  cityId;
  city;
  cityName;
  countryName;
  localPrice;
  processingPurchase = false;
  loadingReviews = true
  reviews = [];
  checkoutError = false
  showingBottomBar = false
  tourNotFound = false
  currentUrlPlaying = undefined
  similarTours = []
  isPotentialTour = false
  mapLoading = true;
  tourLanguages
  selectedLanguage
  tourDefaultLanguage
  checkoutUrl
  isBokunCheckout = false
  usersLanguage
  tourImages = []
  tourOwnershipState = TourOwnershipState.NOT_OWNED
  TourOwnershipState = TourOwnershipState

  stops;
  line;
  bounds;
  isBrowser = false;
  serverstate = {};

  @HostListener('window:scroll', ['$event']) onScroll(e: Event): void {
    if (isPlatformBrowser(this.platformId)) {
      let scrollPosition = window.scrollY || this._document.documentElement.scrollTop || this._document.body.scrollTop || 0;
      if (scrollPosition > 340 && !this.showingBottomBar) {
        this.showingBottomBar = this.tour != undefined && !this.isBokunCheckout;
      } else if (scrollPosition < 340 && this.showingBottomBar) {
        this.showingBottomBar = false;
      }
    }
  }

  constructor(
    @Inject(MatDialog) public dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private purchasesService: PurchasesService,
    public userService: UserService,
    public partnerService: PartnerService,
    private http: HttpClient,
    @Inject(PLATFORM_ID) public platformId: Object,
    @Inject(DOCUMENT) public _document: Document,
    private tourService: TourService,
    private seoService: SeoService,
    private breadcrumbService: BreadcrumbService
  ) {
    console.log("Is server", !isPlatformBrowser(this.platformId))
    if (isPlatformBrowser(this.platformId)) {
      this.language = window.location.hostname.split('.')[0];
    }
    if (this.language == undefined || this.language.length > 2) this.language = 'en';
    this.cityName = this.route.snapshot.paramMap.get('cityName');
    this.countryName = this.route.snapshot.paramMap.get('countryName');
    this.city = this.cities.find(c => (c.names[this.language] ?? c.name).toLowerCase() == this.cityName.toLowerCase());
    this.tourId = this.route.snapshot.paramMap.get('tourId');
    this.strings = JSON.parse(JSON.stringify(stringsJson))['default'][this.language];
    if (isPlatformBrowser(this.platformId)) {
      this.seoService.setTourPageSeo(this.tourId)
      this.audio = new Audio();
      this.isBrowser = true;
    }
  }

  ngOnInit() {
    logEventMethod('tour_page_view');
    this.getTour();
    this.getReviews();
    this.listenForBokunEvents()
    if (this.isBrowser) {
      this.getTourLanguages()
    }
  }

  listenForBokunEvents() {
    if (isPlatformBrowser(this.platformId)) {
      let thisRef = this
      window.addEventListener('message', function (message) {
        if (message.origin == "https://widgets.bokun.io" && message.data.messageType == "GaEvent") {
          let event = message.data.args[1]
          let args = message.data.args[2]
          console.log("Bokun event", event, args)
          if (event == "purchase") {
            let bookingId = args.transaction_id
            thisRef.goToBokunPurchase(bookingId)
          }
        }
      });
    }
  }

  goToBokunPurchase(bookingId) {
    this.router.navigate([`/bokun-purchase/${bookingId}`])
    // todo fix the scrolling issue
  }

  addBokunWidget() {
    if (this.isBokunCheckout && isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        const widget = this._document.getElementById('bokunWidget');
        console.log("Adding bokun widget", this.tour.bokunExperienceId, widget, this.isBokunCheckout);

        if (widget && this.tour.bokunExperienceId) {
          widget.setAttribute("data-src", `https://widgets.bokun.io/online-sales/3171d59b-0269-4f59-b633-be680f49ea4b/experience-calendar/${this.tour.bokunExperienceId}`);

          const script = this._document.createElement('script');
          script.type = 'text/javascript';
          script.src = 'https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=3171d59b-0269-4f59-b633-be680f49ea4b';
          script.async = true;
          this._document.head.appendChild(script);
        }
      }, 100);
    }
  }

  getTourLanguageName() {
    if (this.tour != undefined) {
      return new Intl.DisplayNames(['en'], { type: 'language' }).of(this.tour.language)
    } else {
      return ""
    }
  }

  async getTourLanguages() {
    this.tourLanguages = await this.tourService.getLanguagesForTours()
    if (this.tour != undefined) {
      this.tourLanguages = this.tourLanguages.sort((a, b) => {
        if (a.language == this.tour.language) return -1
        if (b.language == this.tour.language) return 1
        return 0
      })
      this.tourDefaultLanguage = this.tourLanguages.find(l => l.language == this.tour.language)
      this.tourLanguages = this.tourLanguages.filter(l => l.language != this.tour.language)
    }

    let languageCode = navigator.language.split('-')[0]

    if (this.tourLanguages.find(l => l.language == languageCode)) {
      this.usersLanguage = {
        language: languageCode,
        country: navigator.language.split('-')[1].toLowerCase(),
        languageName: langs[languageCode]?.nativeName
      }
    } else {
      this.usersLanguage = undefined
    }

    console.log("User language", this.usersLanguage)

    console.log("Tour languages", this.tourLanguages)
  }

  async getTour() {
    const taskCleanup = this.pendingTasks.add();

    console.log("TIME STAMP 1", new Date().getSeconds())
    try {
      this.tour = await this.tourService.getTour(this.tourId)
    } catch (e) {
      console.error("Error getting tour", e)
    }
    if (this.tour == undefined) {
      this.setTourNotFound()
      taskCleanup();
    } else {
      this.isPotentialTour = this.tour.id.startsWith("p-")
      this.tourImages = this.tour.landmarks.map(l => l.imageUrl).filter(url => url != undefined && !url.endsWith(".svg"))
      console.log("Tour images", this.tourImages)
      this.isBokunCheckout = this.tour.bokunExperienceId != undefined && await this.tourService.isBokunBookingsEnabled()
      switch (true) {
        case this.userService.hasPurchasedTour(this.tour.id):
          this.tourOwnershipState = TourOwnershipState.PURCHASED
          break
        case this.partnerService.isPartnerInCity(this.tour.city.id):
          this.tourOwnershipState = TourOwnershipState.PARTNER_IN_CITY
          break
        default:
          this.tourOwnershipState = TourOwnershipState.NOT_OWNED
      }
      this.landmarks = this.tour.landmarks.filter(l => l.latLng != undefined);
      let stops = this.tour.landmarks.filter(l => l.latLng != null).length;
      let languageCode = this.tour.language != undefined ? this.tour.language : this.tour.language ?? "en"
      let language = langs[languageCode].nativeName;
      this.subTitle = stringFormat(this.strings.tour_sub_title, language, stops, '$2.99');
      var i = 1;
      if (this.tour.ratings != undefined && this.tour.ratings.ratingCount > 0) {
        this.tour.ratings.ratingAvg = this.tour.ratings.ratingSum / this.tour.ratings.ratingCount;
      }
      this.tour.landmarks.forEach(landmark => {
        if (landmark.latLng != undefined) {
          landmark.index = i;
          i++;
        }
      });
      this.setRoute();
      if (this.isBrowser) {
        this.addBokunWidget()
        this.getSimilarTours();
      }
      taskCleanup();
      this.localPrice = await this.purchasesService.getLocalPrice(this.tour);
      console.log("localPrice", this.localPrice)
    }
  }

  async getSimilarTours() {
    let cityTours = (await getDocs(query(collection(getFirestore(), 'guided-tours'), where('city.id', '==', this.city.id), orderBy('ranking', 'desc'), limit(3)))).docs.map(doc => doc.data())
    let countryTours = (await getDocs(query(collection(getFirestore(), 'guided-tours'), where('city.countryCode', '==', this.city.countryCode), orderBy('ranking', 'desc'), limit(3)))).docs.map(doc => doc.data())
    this.similarTours = cityTours.concat(countryTours).filter(t => t.id != this.tour.id).filter((v, i, a) => a.findIndex(t => t.id == v.id) == i).sort((a, b) => b.ratings.ratingSum - a.ratings.ratingSum).slice(0, 3)
  }

  setTourNotFound() {
    this.tourNotFound = true
  }

  getReviews() {
    getDocs(query(collection(getFirestore(), 'guided-tours', this.tourId, 'reviews'))).then(querySnapshot => {
      this.reviews = []
      querySnapshot.docs.forEach(doc => {
        doc.data().reviews.forEach(review => {
          this.reviews.push(review);
        });
      });
      this.loadingReviews = false;
    });
  }

  getSafeHTML(value: {}) {
    const json = JSON.stringify(value, null, 2);
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getLanguage(lang) {
    langs[lang].nativeName;
  }

  getPreviewAudioUrl() {
    let genericPreviewUrl = "https://storage.googleapis.com/explorer-f45e6/audio/preview/audio_default_preview_en.mp3"
    return this.isPotentialTour ? genericPreviewUrl : this.tour.landmarks[0].audioUrl
  }

  async playAudio(audioUrl = undefined) {
    let currentUrlPlaying = audioUrl == undefined ? this.getPreviewAudioUrl() : audioUrl;
    if (this.playingState == PLAYING && this.currentUrlPlaying == currentUrlPlaying) {
      this.audio.pause();
      this.playingState = NOT_PLAYING;
    } else {
      this.audio.pause();
      this.currentUrlPlaying = currentUrlPlaying
      this.playingState = LOADING;
      this.audio.src = currentUrlPlaying;
      this.audio.load();
      await this.audio.play();
      this.playingState = PLAYING;
      this.audio.addEventListener('ended', () => {
        this.playingState = NOT_PLAYING;
      });
    }
  }

  setRoute() {
    this.stops = {
      type: 'FeatureCollection',
      features: this.tour?.landmarks
        .filter(l => l.latLng != undefined)
        .map(stop => ({
          type: 'Feature',
          properties: {
            stop: stop,
          },
          geometry: {
            type: 'Point',
            coordinates: [stop.latLng[1], stop.latLng[0]],
          },
        })),
    };
    if (this.tour.routeInstructions != undefined) {
      this.line = this.tour.routeInstructions.geometry.map(x => [x.lng, x.lat]);
    } else {
      this.line = this.stops.features.map(x => x.geometry.coordinates);
    }
    if (this.line.length > 0) {
      this.bounds = this.line.reduce(
        (bounds, coord) => {
          return bounds.extend(<any>coord);
        },
        new LngLatBounds(this.line[0], this.line[0])
      );
    }
    this.mapLoading = false;
  }

  @ViewChild('loginModal', { static: true }) loginModalRef!: ElementRef;

  async showBokunCheckout() {
    if (isPlatformBrowser(this.platformId)) {
      const bokunWidget = this._document.getElementById('bokunWidget');
      if (bokunWidget) {
        bokunWidget.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
      }
    }
  }

  async buyTour() {
    if (isPlatformBrowser(this.platformId)) {
      logEventMethod('buy_tour_click');

      this.showChooseLanguageModal()

      try {
        if (this.checkoutUrl == undefined) {
          this.checkoutUrl = await this.getCheckoutUrl()
        }
        if (this.selectedLanguage != undefined && this.selectedLanguage.language == this.tour.language) {
          window.location.href = this.checkoutUrl;
        }
      } catch (e) {
        console.error(e);
        this.checkoutError = true;
      }
    }
  }

  async getCheckoutUrl(languageCode = undefined) {
    let url = await this.purchasesService.getCheckoutUrl(
      this.tourId,
      this.localPrice?.id,
      window.location.href,
      `${window.location.origin}/tour-purchased/${this.tourId}`,
      this.userService.getUserId(),
      this.userService.getUserEmail(),
      languageCode == this.tour.language ? undefined : languageCode
    ) as any;

    console.log("Checkout URL with language", languageCode)

    return url
  }

  async selectLanguage(language) {
    let isLoggedIn = this.userService.isLoggedIn.value

    if (isPlatformBrowser(this.platformId) && isLoggedIn) {
      this.selectedLanguage = language
      this.showCheckingOutModal()
      if (language.language != this.tour.language) {
        this.checkoutUrl = await this.getCheckoutUrl(language.language)
        window.location.href = this.checkoutUrl;
      } else if (this.checkoutUrl != undefined) {
        window.location.href = this.checkoutUrl;
      }
    }
  }

  onSuccessfulLogin(event) {
    this.closeLoginModal();
    this.buyTour();
  }

  closeLoginModal() {
    this._document.getElementById('closeLoginBtn').click();
  }

  showCheckingOutModal() {
    this.checkoutError = false
    this._document.getElementById('openCheckingOutModalBtn').click();
  }

  showChooseLanguageModal() {
    this._document.getElementById('openChooseLanguageModalBtn').click();
  }

  goToApp() {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
        logEventMethod('alltours_cta_click', { type: 'ios' });
        window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
      } else {
        logEventMethod('alltours_cta_click', { type: 'not-ios' });
        window.open('https://play.google.com/store/apps/details?id=app.alltours');
      }
    }
  }

  openFullscreenImageModal(index: number) {
    this._document.getElementById('fullscreenImageModal').click();
  }

  paymentMethods = [
    { image: 'apple-pay.svg' },
    { image: 'google-pay.svg' },
    { image: 'visa.png' },
    { image: 'mastercard.png' },
    { image: 'paypal.png' },
    { image: 'jcb.png' },
    { image: 'alipay.svg' }
  ]

  howItWorks = [
    {
      title: "Buy the tour",
      description: "You'll be taken to a secure checkout page.",
      icon: "shopping_cart"
    },
    {
      title: 'Download the AllTours app',
      description: "It shouldn't take long!",
      icon: "download"
    },
    {
      title: 'Login to the app',
      description: "You'll see the tour you just bought.",
      icon: "passkey"
    },
    {
      title: 'Start the tour!',
      description: "You'll be guided through the city with audio and a map.",
      icon: "play_arrow"
    },
  ];

  allToursBenefits = [
    {
      title: "Explore at Your Own Pace",
      description: "Take breaks, linger at landmarks, or move quickly—it's your tour, on your time.",
      icon: "self_improvement"
    },
    {
      title: "Ask Questions Anytime",
      description: "Got a question? Ask the in-app guide at any point during the tour for more information about what you’re seeing.",
      icon: "person_raised_hand"
    },
    {
      title: "GPS-Triggered Audio",
      description: "As you approach each landmark, the audio guide plays automatically, making your tour seamless.",
      icon: "share_location"
    },
    {
      title: "Follow a Guided Route",
      description: "Navigate the city effortlessly with AllTours' built-in map, guiding you from one landmark to the next.",
      icon: "route"
    },
    {
      title: "Customize Your Tour",
      description: "Add extra audio tracks to dive deeper into the city’s history, politics, culture, and more, tailoring your experience.",
      icon: "instant_mix"
    },
    {
      title: "No Internet Needed",
      description: "Download the tour in advance and enjoy it offline—no Wi-Fi or data required.",
      icon: "wifi_off"
    },
    {
      title: "Tour Anytime, Anywhere",
      description: "Unlike scheduled group tours, you can start your audio tour whenever you like—day or night.",
      icon: "partly_cloudy_day"
    }
  ];

  faqs = [
    {
      title: 'What is an audio tour?',
      description:
        'An audio tour is like having a tour guide on your phone. You are shown a map & a route so you know how to get to each landmark. The audio then begins playing when you reach each landmark.',
    },
    {
      title: 'What do I do if the app is not working properly?',
      description: "If you're experiencing issues, try restarting the app. If problems persist, ensure your app is up-to-date or contact <a href='mailto:kes@alltours.app'>support</a> for assistance.",
    },
    {
      title: 'Do I need an account to use AllTours?',
      description: "To purchase a tour on our website you'll first need to create an account so that we can sync your purchases with the app. You'll need to login to the app with the same account to access your tours."
    },
    {
      title: 'Can I pause or resume a tour?',
      description: 'Yes, you can pause and resume any tour at your convenience. The app will remember where you left off so you can continue later.',
    },
    {
      title: 'What data does AllTours collect?',
      description: 'AllTours collects minimal data necessary to provide location-based services. For more information, you can view our <a href="https://alltours.app/policy" target="_blank">Privacy Policy</a>.',
    },
    {
      title: 'Do I need internet access to use AllTours?',
      description: 'All tours can be downloaded for offline use, but for using the Chat feature, an internet connection is required.',
    },
    {
      title: 'Is AllTours free to use?',
      description: 'AllTours is free to download but the audio tours are paid. You can purchase tours on our website or in the app.',
    },
  ];
}

const NOT_PLAYING = 0;
const LOADING = 1;
const PLAYING = 2;

enum TourOwnershipState {
  NOT_OWNED,
  PURCHASED,
  PARTNER_IN_CITY
}