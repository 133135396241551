import { Component, OnInit, Inject, PLATFORM_ID, makeStateKey, TransferState, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import stringsJson from '../../../../assets/strings/strings.json';
import { fadeInUpAnimation } from 'src/app/utils/animations';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { collection, getDocs, getFirestore, limit, orderBy, query, where } from '@angular/fire/firestore';
import { logEventMethod } from '../../../utils/logging';

const DATA_KEY = makeStateKey<any>('featured_tours');

@Component({
  selector: 'app-all-tours-home',
  templateUrl: './all-tours-home.component.html',
  styleUrls: ['./all-tours-home.component.css', '../../../app.component.css'],
  animations: [fadeInUpAnimation]
})
export class AllToursHomeComponent implements OnInit {
  strings;
  language;
  screenshots;
  featuredTours = [];
  appStoreImage;
  serverstate = {};

  constructor(
    @Inject(MatDialog) public dialog: MatDialog,
    private titleService: Title,
    private route: ActivatedRoute,
    public router: Router,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) public platformId: Object,
    @Inject(DOCUMENT) public _document: Document,
  ) { }

  ngOnInit() {
    // Critical initialization first
    this.initializeLanguage();
    this.setSeoText();
    this.setAppStoreImage();
    this.setScreenshots();

    // Load data based on platform
    if (isPlatformBrowser(this.platformId)) {
      if (this.transferState.hasKey(DATA_KEY)) {
        // Client: Use transferred state if available
        const dataFromServer = JSON.parse(this.transferState.get(DATA_KEY, {}));
        if (dataFromServer) {
          this.featuredTours = dataFromServer.data.featuredTours;
          this.transferState.remove(DATA_KEY);
        }
      } else {
        // Client: No transferred state, load data
        setTimeout(() => {
          this.getFeaturedTours();
        }, 0);
      }
    } else {
      this.getFeaturedTours()
    }
  }

  private initializeLanguage() {
    if (isPlatformBrowser(this.platformId)) {
      this.language = window.location.hostname.split('.')[0];
    }
    if (this.language == undefined || this.language.length > 2) this.language = 'en';
    this.strings = JSON.parse(JSON.stringify(stringsJson))['default'][this.language];
  }

  async getFeaturedTours() {
    const querySnapshot = await getDocs(query(
      collection(getFirestore(), 'guided-tours'),
      where('language', '==', "en"),
      orderBy('ranking', 'desc'),
      limit(3)
    ));

    this.featuredTours = [];
    querySnapshot.forEach(doc => {
      this.featuredTours.push(doc.data());
    });

    if (!isPlatformBrowser(this.platformId)) {
      this.serverstate['featuredTours'] = this.featuredTours;
      this.transferState.set(DATA_KEY, JSON.stringify({ data: this.serverstate }));
    }
  }

  setAppStoreImage() {
    if (isPlatformBrowser(this.platformId)) {
      let isIphone = navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)
      this.appStoreImage = isIphone ? 'appStoreButton.png' : 'googlePlayButton.png';
    }
  }

  setScreenshots() {
    this.screenshots = [
      {
        id: 0,
        title: this.strings.screenshot_title_0,
        text: this.strings.screenshot_sub_title_0,
        img: '../../../assets/images/alltours/converted/screenshots/screenshot_0.png',
      },
      {
        id: 1,
        title: this.strings.screenshot_title_1,
        text: this.strings.screenshot_sub_title_1,
        img: '../../../assets/images/alltours/converted/screenshots/screenshot_1.png',
      },
      {
        id: 2,
        title: this.strings.screenshot_title_2,
        text: this.strings.screenshot_sub_title_2,
        img: '../../../assets/images/alltours/converted/screenshots/screenshot_2.png',
      },
      {
        id: 3,
        title: this.strings.screenshot_title_3,
        text: this.strings.screenshot_sub_title_3,
        img: '../../../assets/images/alltours/converted/screenshots/screenshot_3.png',
      },
    ];
  }

  setSeoText() {
    this._document.title =
      this.language == 'en'
        ? 'AllTours - Audio tours in 50+ languages'
        : 'AllTours - ' + this.strings.any_city_any_language;
    if (isPlatformBrowser(this.platformId)) {
      this._document.head.children.namedItem('description')['content'] = this.strings.seo_description_default;
    }
  }

  goToApp() {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
        this.goToAppStore()
      } else {
        this.goToPlayStore()
      }
    }
  }

  goToAppStore() {
    if (isPlatformBrowser(this.platformId)) {
      logEventMethod('alltours_cta_click', { type: 'ios' });
      window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
    }
  }

  goToPlayStore() {
    if (isPlatformBrowser(this.platformId)) {
      logEventMethod('alltours_cta_click', { type: 'not-ios' });
      window.open('https://play.google.com/store/apps/details?id=app.alltours');
    }
  }
}
