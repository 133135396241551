<div class="hero-section">
  <div class="hero-background">
    <picture>
      <source srcset="
          ../../../assets/images/alltours/converted/london-480w.webp 480w,
          ../../../assets/images/alltours/converted/london-800w.webp 800w,
          ../../../assets/images/alltours/converted/london.webp 1200w
        " type="image/webp" sizes="100vw" />
      <source srcset="
          ../../../assets/images/alltours/converted/london-480w.jpg 480w,
          ../../../assets/images/alltours/converted/london-800w.jpg 800w,
          ../../../assets/images/alltours/converted/london.jpg 1200w
        " type="image/jpeg" sizes="100vw" />
      <img class="hero-image" src="../../../assets/images/alltours/converted/london.jpg" alt="London skyline background"
        loading="eager" fetchpriority="high" />
    </picture>
  </div>
  <div class="hero-content">
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a class="bread-link" href="">{{ strings.home }}</a>
          </li>
          <li class="breadcrumb-item" aria-current="page">{{ breadcrumbName }}</li>
        </ol>
      </nav>
      <div class="row align-items-center">
        <div class="col-12 col-lg-10">
          <div class="hero-text">
            <h1>Your World, Your Tour – Any City, Any Language</h1>
            <p class="hero-subtitle">
              Turn any place into your own guided adventure. Explore over 1,000 cities or create your own audio
              tour—anywhere, in any language.
            </p>
            <div class="hero-stats">
              <div class="stat-item">
                <span class="material-symbols-rounded">headphones</span>
                <span>{{ totalTours }} Tours</span>
              </div>
              <div class="stat-item">
                <span class="material-symbols-rounded">location_city</span>
                <span>{{ overview?.cities.length }} Cities</span>
              </div>
              <div class="stat-item">
                <span class="material-symbols-rounded">public</span>
                <span>{{ overview?.countries.length }} Countries</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="countries-section">
  <div class="container">
    <div class="section-header">
      <h2>Popular Countries</h2>
      <div class="decorative-line"></div>
    </div>
    <div class="row g-2">
      <div *ngFor="let country of overview?.countries.slice(0, 18)" class="col-6 col-sm-4 col-md-3 col-lg-2">
        <a [href]="'/tour/' + country.name.toLowerCase()" class="country-card">
          <div class="country-image">
            <img class="inner-country-image" [src]="country.imageUrl" [alt]="country.name + ' country image'"
              loading="lazy" onerror="this.onerror=null; this.src='../../../assets/images/alltours/placeholder.png'" />
            <div class="country-overlay">
              <div class="country-info">
                <h3>
                  <img class="country-flag" src="../../../assets/flags/{{ country.id.toLowerCase() }}.png"
                    [alt]="'Flag of ' + country.name" />
                  {{ country.name }}
                </h3>
                <div class="country-stats">
                  <div class="stat-tours">
                    <span class="material-symbols-rounded">headphones</span>
                    <span *ngIf="country.total > 0">{{ country.total }} Tours</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="other-items-section">
      <h3>Other Countries</h3>
      <div class="row g-2">
        <div *ngFor="let country of otherCountries" class="col-6 col-sm-4 col-md-3 col-lg-2">
          <a class="other-item-link" [href]="'/tour/' + country.name.toLowerCase()">
            <img class="country-flag-small" src="../../../assets/flags/{{ country.id.toLowerCase() }}.png"
              [alt]="'Flag of ' + country.name" />
            {{ country.name }}
            <span class="text-muted" *ngIf="country.total > 0">({{ country.total }})</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="top-cities-section">
  <div class="container">
    <div class="section-header">
      <h2>Popular Cities</h2>
      <div class="decorative-line"></div>
    </div>
    <div class="row g-2">
      <div *ngFor="let city of overview?.cities.slice(0, 18)" class="col-6 col-sm-4 col-md-3 col-lg-2">
        <a [href]="'/tour/' + city.countryName.toLowerCase() + '/' + city.name.toLowerCase()" class="city-card">
          <div class="city-image">
            <img class="inner-city-image" [src]="city.imageUrl" [alt]="city.name + ' city image'" loading="lazy"
              onerror="this.onerror=null; this.src='../../../assets/images/alltours/placeholder.png'" />
            <div class="city-overlay">
              <div class="city-info">
                <h3>{{ city.name }}</h3>
                <div class="city-stats">
                  <div class="stat-tours">
                    <span class="material-symbols-rounded">headphones</span>
                    <span>{{ city.total }} Tours</span>
                  </div>
                  <div class="stat-country">
                    <img class="country-flag-small" src="../../../assets/flags/{{ city.countryCode.toLowerCase() }}.png"
                      [alt]="'Flag of ' + city.countryName" loading="lazy" />
                    <span>{{ city.countryName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div *ngIf="overview?.cities.length > 18" class="other-items-section">
      <h3>Other Cities</h3>
      <div class="row g-2">
        <div *ngFor="let city of overview?.cities.slice(18)" class="col-6 col-sm-4 col-md-3 col-lg-2">
          <a class="other-item-link" [href]="'/tour/' + city.countryName.toLowerCase() + '/' + city.name.toLowerCase()">
            {{ city.name }}
            <span class="text-muted">({{ city.total }})</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>