.hero-section {
  position: relative;
  height: 45vh;
  width: 100%;
  overflow: hidden;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.heroImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4));
}

.ontopCover {
  position: relative;
  z-index: 2;
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.breadcrumb-nav {
  padding-top: 24px;
}

.breadcrumb {
  margin: 0;
  padding: 0;
  background: transparent;
}

.title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 16px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.subtitle {
  font-size: 1.2rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 24px;
}

.cityLanding {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.content-section {
  background: #fafafa;
  padding: 48px 0;
}

.map-section {
  margin: 48px 0;
  border: none;
  border-radius: 12px;
  overflow: hidden;
}

.map-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 24px;
}

.step-container {
  display: flex;
  align-items: flex-start;
  padding: 12px 0;
}

.step-content {
  margin-left: 16px;
  flex: 1;
}

.cta-container {
  margin-top: auto;
  text-align: center;
}

.ctaBtnMini {
  width: 100%;
  max-width: 400px;
  margin-top: 24px;
}

.tours-grid,
.tours-grid-extended {
  justify-content: center;
  margin: 0 -16px;
}

.cities-section {
  margin-top: 48px;
}

.section-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin: 24px 0;
}

.city-name {
  display: block;
  color: #666;
  text-decoration: none;
  padding: 8px 0;
  transition: color 0.2s ease;
}

.city-name:hover {
  color: #92b323;
}

.hidden-button {
  display: none;
}

.bread-link {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  transition: color 0.2s ease;
}

.bread-link:hover {
  color: white;
  text-decoration: none;
}

.breadcrumb-item {
  color: rgba(255, 255, 255, 0.9);
}

.breadcrumb-item+.breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.6);
}

#article {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
  padding: 32px;
  min-height: 100vh;
}

#article:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

#article h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #333;
}

#article h2 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 32px;
  margin-bottom: 16px;
  color: #444;
}

#article p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 24px;
}

#article li {
  color: #666;
  margin-bottom: 12px;
  line-height: 1.5;
}

.mapInfoHolder {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  position: relative;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.icon {
  color: #92b323;
  font-size: 24px;
}

.primaryText {
  color: #333;
  font-size: 16px;
  margin-bottom: 4px;
}

.secondaryText {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
}

@media (max-width: 992px) {
  .title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1.1rem;
  }

  .mapInfoHolder {
    margin-top: 24px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: 40vh;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .content-section {
    padding: 32px 0;
  }
}

@media (max-width: 576px) {
  .hero-section {
    height: 35vh;
  }

  .title {
    font-size: 1.75rem;
  }

  .breadcrumb-nav {
    padding-top: 16px;
  }

  .content-section {
    padding: 24px 0;
  }

  .map-section {
    margin: 24px 0;
  }
}